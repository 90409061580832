export const environment = {
  apiUrl: 'https://api.cartas.qa.ticepro.com',
  apiGraphqlUrl: 'https://api.cartas.qa.ticepro.com/graphql',
  apiDamUrl: 'https://api.dam.qa.ticepro.com',
  apiGqlDamUrl: 'https://api.dam.qa.ticepro.com/graphql',
  templateAppUrl: 'https://app.cartas.qa.ticepro.com',
  pdfApiUrl: 'https://pdf.cartas.qa.ticepro.com',
  keycloakConfig: {
    clientId: 'cartas-admin',
    realm: 'Ticepro',
    url: 'https://accounts.ticepro.com/auth/',
  },
};
